import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../components/DefaultLayout';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Img from 'gatsby-image';
import UniversalLink from '../components/UniversalLink';
import CpaLogo from '../components/CpaLogo';
import ArchiveList from '../components/ArchiveList';

interface Props {}

// @ts-ignore
const Archive = props => {
  const {
    data: {
      page,
      wp,
      allWpPost: { nodes, pageInfo },
    },
    pageContext: { archiveType, archivePath },
  } = props;
  return (
    <DefaultLayout>
      <Seo title='Blog' postSchema={JSON.parse(wp.seo.contentTypes.post.schema.raw)} />
      <div className='flex flex-col lg:flex-row'>
        <div className='sidebar w-full lg:w-2/3 overflow-hidden'>
          <div className='container max-w-4xl mx-auto mt-24 mb-1' dangerouslySetInnerHTML={{ __html: page.content }} />
          <div className='flex justify-around flex-wrap container mx-auto mt-0 lg:pl-20 pr-0'>
            {nodes &&
              // @ts-ignore
              nodes.map(node => {
                const cleanExcerpt = node.excerpt.replace(/[[\]\\]/g, '');
                return (
                  <div key={node.title} className='w-5/12	rounded overflow-hidden shadow-md mb-10'>
                    {!!node?.featuredImage?.node?.localFile?.childImageSharp && (
                      <Img
                        placeholderClassName={'w-full'}
                        fluid={node.featuredImage.node.localFile.childImageSharp.fluid}
                      />
                    )}
                    <div className='flex flex-col px-2 py-4'>
                      <h4 className='mb-5 text-xl font-bold leading-8 prose max-w-none blog-heading-link'>
                        <UniversalLink to={node.uri}>
                          <span dangerouslySetInnerHTML={{ __html: node.title }} />
                        </UniversalLink>
                      </h4>
                      <div className='pb-4 prose'>
                        <span>by margento | </span>
                        <span dangerouslySetInnerHTML={{ __html: node.date }} />
                      </div>
                      <div
                        className='text-gray-700 text-sm mb-5 max-w-none prose'
                        dangerouslySetInnerHTML={{ __html: cleanExcerpt }}
                      />
                      <button className='read-more-button bg-white hover:bg-secondary-base text-secondary-base border-solid border-2 border-secondary-base hover:text-white rounded-sm mt-2'>
                        <UniversalLink to={node.uri}>Read Blog Post</UniversalLink>
                      </button>
                    </div>
                    {/*<div className="px-6 pb-4">*/}
                    {/*  {!!node?.categories?.nodes && node.categories.nodes.map((category: any) => (*/}
                    {/*    <span key={category.name}*/}
                    {/*          className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2">*/}
                    {/*        #{category.name}*/}
                    {/*    </span>*/}
                    {/*  ))}*/}
                    {/*</div>*/}
                  </div>
                );
              })}
          </div>
        </div>
        <article className='w-full lg:w-1/3 pl-16 pr-24'>
          <CpaLogo />
          <ArchiveList />
        </article>
      </div>
    </DefaultLayout>
  );
};

export const query = graphql`
  query ArchivePage(
    $offset: Int!
    $perPage: Int! #        $userDatabaseId: Int #        $categoryDatabaseId: Int
  ) {
    page: wpPage(id: { eq: "cG9zdDoyNDk=" }) {
      title
      content
      databaseId
      slug
      featuredImage {
        node {
          localFile {
            ...HeroImage
          }
        }
      }
    }
    wp {
      seo {
        contentTypes {
          post {
            schema {
              raw
            }
          }
        }
      }
    }
    allWpPost(
      limit: $perPage
      skip: $offset
      #            filter: {
      #                author: { databaseId: { eq: $userDatabaseId } }
      #                categories: {
      #                    nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } }
      #                }
      #            }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...PostPreviewContent
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
  }
`;

export default Archive;
